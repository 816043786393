import Vue from "vue";
import Vuex from "vuex";
import { mapState } from "vuex";
import axios from "axios";
axios.defaults.headers.post["X-CSRF-TOKEN"] = window.csrf_token;

Vue.use(Vuex);

if (window.app == undefined) {
	window.app = {};
}
window.app.store = new Vuex.Store({
	state: {
		example: "",
		notepad: "",
		choices: null,
		term: "",
		productsLink:
			window.location.origin + "/api/shop" + window.location.pathname,
		origin: window.location.origin,
		count: 0,
		loaded: false,
		products: { data: [] },
		filtered_products2: [],
		shops: { data: [] },
		market: {
			tab: "overview",
			isSearching: false,
		},
		user: null,
		quick_purchase: {
			link: window.quick_purchase_link,
			info: null,
		},
		productVariationPrice: 0,
		searchTerm: "",
	},
	mutations: {
		add_dot(state) {
			state.example += ".";
		},
		SET_CHOICES(state, choices_array) {
			state.choices = choices_array;
		},
		SET_MARKET_TAB(state, tab) {
			state.market.tab = tab;
		},
		term_update(state, t) {
			state.term = t;
		},
		products_update(state, p) {
			state.products.data = p;
			state.filtered_products2 = state.products.data.filter(function (a) {
				return (
					a.name
						.toLocaleLowerCase()
						.includes(state.term.toLocaleLowerCase()) == true
				);
			});
		},
		is_loaded(state) {
			//Used to test if the products have been fetched
			state.loaded = true;
		},
		SET_PRODUCTS(state, products_input) {
			if (
				state.products.hasOwnProperty("data") &&
				products_input.hasOwnProperty("data") &&
				state.products.data.length != 0
			) {
				state.products.data.push(...products_input.data);
			} else {
				state.products.data = products_input.data;
			}

			// Repopulating state objects instead of adding or over-writing
			// their properties makes Vue getters reactive.

			if (products_input.hasOwnProperty("totalPages")) {
				state.products = {
					...state.products,
					totalPages: products_input.totalPages,
				};
			}

			if (products_input.hasOwnProperty("totalResults")) {
				state.products = {
					...state.products,
					totalResults: products_input.totalResults,
				};
			}

			if (products_input.hasOwnProperty("itemsPerPage")) {
				state.products = {
					...state.products,
					itemsPerPage: products_input.itemsPerPage,
				};
			}

			if (products_input.hasOwnProperty("currentPage")) {
				state.products = {
					...state.products,
					currentPage: products_input.currentPage,
				};
			}
			if (products_input.hasOwnProperty("onLastPage")) {
				state.products = {
					...state.products,
					onLastPage: products_input.onLastPage,
				};
			}

			// Final step to remove duplicates from current products
			state.products.data = state.products.data.filter(
				(product, index, self) =>
					self.findIndex((item) => {
						return (
							product.name === item.name && product.slug === item.slug
						);
					}) === index
			);
		},
		SET_SHOPS(state, shops_input) {
			if (
				state.shops.hasOwnProperty("data") &&
				shops_input.hasOwnProperty("data") &&
				state.shops.data.length != 0
			) {
				state.shops.data.push(...shops_input.data);
			} else {
				state.shops.data = shops_input.data;
			}

			// Repopulating state objects instead of adding or over-writing
			// their properties makes Vue getters reactive.
			if (shops_input.hasOwnProperty("nextPageUrl")) {
				state.shops = {
					...state.shops,
					nextPageUrl: shops_input.nextPageUrl,
				};
			}

			if (shops_input.hasOwnProperty("previousPageUrl")) {
				state.shops = {
					...state.shops,
					previousPageUrl: shops_input.previousPageUrl,
				};
			}

			if (shops_input.hasOwnProperty("totalPages")) {
				state.shops = {
					...state.shops,
					totalPages: shops_input.totalPages,
				};
			}

			if (shops_input.hasOwnProperty("totalResults")) {
				state.shops = {
					...state.shops,
					totalResults: shops_input.totalResults,
				};
			}

			if (shops_input.hasOwnProperty("itemsPerPage")) {
				state.shops = {
					...state.shops,
					itemsPerPage: shops_input.itemsPerPage,
				};
			}

			if (shops_input.hasOwnProperty("currentPage")) {
				state.shops = {
					...state.shops,
					currentPage: shops_input.currentPage,
				};
			}
			if (shops_input.hasOwnProperty("onLastPage")) {
				state.shops = {
					...state.shops,
					onLastPage: shops_input.onLastPage,
				};
			} // Final step to remove duplicates from current shops
			state.shops.data = state.shops.data.filter(
				(shop, index, self) =>
					self.findIndex((item) => {
						return shop.name === item.name && shop.slug === item.slug;
					}) === index
			);
		},
		SET_IS_SEARCHING(state, searching) {
			state.market.isSearching = searching;
		},
		SET_SEARCH_TERM(state, searchTerm) {
			state.searchTerm = searchTerm;
		},
		SET_USER(state, user_information) {
			state.user = user_information;
		},
		SET_QUICK_PURCHASE(state, quick_purchase_information) {
			state.quick_purchase.info = quick_purchase_information;
		},
		SET_QUANTITY(state, quantity) {
			let updated = false;
			if (state.choices == null) {
				state.choices = [];
			}
			for (let counter = 0; counter < state.choices.length; counter++) {
				if (state.choices[counter].choice == "quantity") {
					state.choices[counter].value = quantity;
					updated = true;
				}
			}
			if (updated == false) {
				state.choices.push({ choice: "quantity", value: quantity });
			}
		},
		SET_PRODUCT_VARIATION_PRICE(state, price) {
			state.productVariationPrice = price;
		},
		CLEAR_SEARCH(state) {
			state.products = { data: [] };
			state.shops = { data: [] };
		},
	},
	getters: {
		example: (state) => state.example,
		notepad: (state) => state.notepad,
		term: (state) => state.term,
		choices: (state) => state.choices,
		message: (state, getters) =>
			`The number in count is ${getters.productCount}`,
		lowercaseTerm: (state) => state.term.toLocaleLowerCase(),
		productCount: (state) => state.products.data.length,
		products: (state) => state.products.data,
		shops: (state) => state.shops.data,
		has_loaded: (state) => state.loaded,
		filtered_products: (state, getters) =>
			getters.products.filter(function (a) {
				return (
					a.name
						.toLocaleLowerCase()
						.includes(state.term.toLocaleLowerCase()) == true
				);
			}),
		marketTab: (state) => state.market.tab,
		marketIsSearching: (state) => state.market.isSearching,
		quick_purchase: (state) => state.quick_purchase,
		productQuantity: function (state) {
			if (state.choices == null) {
				return 0;
			}
			for (let counter = 0; counter < state.choices.length; counter++) {
				if (state.choices[counter].choice == "quantity") {
					return state.choices[counter].value;
				}
			}
			return 0;
		},
		productVariationPrice: (state) => state.productVariationPrice,
		productsCurrentPage: (state) => state.products.currentPage,
		productsTotalPages: (state) => state.products.totalPages,
		shopsCurrentPage: (state) => state.shops.currentPage,
		shopsTotalPages: (state) => state.shops.totalPages,
		searchTerm: (state) => state.searchTerm,
	},
	actions: {
		async_add_dot({ context }) {
			setTimeout(() => {
				context.commit("add_dot");
			}, 1000);
		},
		async_add_dot({ context }) {
			setTimeout(() => {
				context.commit("add_dot");
			}, 1000);
		},
		async_add_dot({ commit }) {
			let _this = this;
			setTimeout(() => {
				_this.commit("add_dot");
			}, 1000);
		},
		set_choices({ commit }, user_choices) {
			this.commit("SET_CHOICES", user_choices);
		},
		set_term({ commit }, term) {
			this.commit("term_update", term);
		},
		fetchProducts({ commit }) {
			// fetch data from a url endpoint
			let _this = this;
			axios
				.get(this.state.productsLink)
				.then(function (res) {
					_this.commit("products_update", res.data);
					_this.commit("is_loaded");
				})
				.catch((error) => console.error(error));
		},
		set_products({ commit }, products_input) {
			this.commit("SET_PRODUCTS", products_input);
		},
		set_shops({ commit }, shops_input) {
			this.commit("SET_SHOPS", shops_input);
		},
		set_market_tab({ commit }, tab) {
			this.commit("SET_MARKET_TAB", tab);
		},
		set_is_searching({ commit }, searching) {
			this.commit("SET_IS_SEARCHING", searching);
		},
		get_user({ commit }) {
			let _this = this;
			axios
				.get(this.state.user_link)
				.then(function (res) {
					_this.commit("SET_USER", res.data);
				})
				.catch((error) => console.error(error));
		},
		get_quick_purchase_information({ commit }) {
			let _this = this;
			axios
				.get(this.state.quick_purchase.link)
				.then(function (res) {
					_this.commit("SET_QUICK_PURCHASE", res.data);
				})
				.catch((error) => console.error(error));
		},
		set_quantity({ commit }, quantity) {
			this.commit("SET_QUANTITY", parseInt(quantity));
		},
		set_product_variation_price({ commit }, price) {
			this.commit("SET_PRODUCT_VARIATION_PRICE", parseFloat(price));
		},
		set_search_term({ commit }, search_term) {
			this.commit("SET_SEARCH_TERM", search_term);
		},
		clear_search({ commit }) {
			this.commit("CLEAR_SEARCH");
		},
	},
});
